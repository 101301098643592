import { useNavigate } from "react-router-dom";
import "./home.scss"
import ProfitSharing from "./sections/ProfitSharing";
import CenterHookLine from "./sections/CenterHookLine";
import Licences from "./sections/Licences";
import Header from '../components/Footer';
import Animated from "./AnimatedBack";

//Images
// import imghome from "../assets/img/Homeimg.jpg";
// import liberalhome from "../assets/img/liberalhome.png";
// import entreprisehome from "../assets/img/entreprisehome.png";

const Home = () => {
  const navigate = useNavigate();
  return (
      <div className="home">
        Figurine
        {/* <Animated />
        <CenterHookLine />
        <ProfitSharing />
        <Licences />
        <Header /> */}
      </div>
  );
};

export default Home;
