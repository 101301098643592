import "./header.scss";
import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import logo from "../logo.svg";

const Header = () => {
    const navigate = useNavigate();
    const [color, setColor] = useState(false)
    const changeColor = () => {
        if (window.scrollY >= 60) {
            setColor(true)
        } else {
            setColor(false)
        }
    }
    window.addEventListener('scroll', changeColor)
    const navUl = useRef();
    const navToggle = useRef();
    const ToggleMenu = () => {
        var visibility = navUl.current.getAttribute('data-visible')
        if (visibility === "false") {
            navUl.current.setAttribute('data-visible', "true")
            navToggle.current.setAttribute('aria-expanded', "true")

        } else {
            navUl.current.setAttribute('data-visible', "false")
            navToggle.current.setAttribute('aria-expanded', "false")

        }

    }
    const handleClickNav = (e, nav) => {
        e.preventDefault();
        try{
            document.getElementById(nav).scrollIntoView({ behavior: "smooth" })
        }catch (error){
            navigate("/")
        }

    }
    return (
        <>
            <header className={color ? "header header-bg flex" : "header flex"}>
                <div>
                    <h1 className="logo">LEEVA</h1>
                </div>
                <button ref={navToggle} className="menu-toggle" aria-expanded="false" aria-controls="nav-ul" onClick={ToggleMenu}></button>
                <nav className="nav-bar">
                    <ul id="nav-ul" ref={navUl} className="nav-ul" data-visible="false">
                        <li><a href="/" onClick={(e) => { handleClickNav(e, "center-hook-line") }}>Accueil</a></li>
                        <li><a href="/" onClick={(e) => { handleClickNav(e, "profit-sharing") }}>Profit Sharing</a></li>
                        <li><a href="/" onClick={(e) => { handleClickNav(e, "licences") }}>Nos Produits</a></li>
                        <li><a href="/">Entreprise</a></li>
                        <li><a href="/">Affiliation</a></li>
                        <li><a href="/">Contact</a></li>

                        {!localStorage.getItem("email") ? (
                            <>
                                <button type="button" className="discover" onClick={() => navigate("/product/?product=1")}><strong>Commencer</strong></button>
                            </>
                        ) : (
                            <>
                                <li><a href="/" onClick={() => { localStorage.removeItem("email"); localStorage.removeItem("password") }}>Se déconnecter</a></li>
                            </>
                        )
                        }

                    </ul>
                </nav>
            </header>
        </>
    );
};

export default Header;
